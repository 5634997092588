import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Input, Table } from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../../../GlobalComponents/Footer";
import Navbar from "../../../Navbar";
import DashboardSidebar from "../../NewDashboardSidebar";
import { collectionBookingService } from "../../../../../../services/collectionBooking.services";
import useDebounce from "../../../../../../helpers/UseDebounce";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import dateFormat from "dateformat";
import moment from "moment";
import Norecord from "./Norecord.js";
import { exportXls } from "../Common/ExportXls";
import CollectionDetails from "../Common/CollectionDetails";
import Collection from "../Common/Collection";
import DatePicker from "react-datepicker";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";

const pastYearDate = moment().subtract(6, "months").format("YYYY-MM-DD");
const yesterdayDate = moment().subtract(1, "days").format("YYYY-MM-DD");
const searchFromDate = new Date(pastYearDate);
const searchToDate = new Date(yesterdayDate);

const Index = () => {
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/MM/yyyy");
    const [search, setSearch] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [totalItemsCount, setTotalItemsCount] = useState("");
    const [loading, setLoading] = useState(false);
    const [bookingList, setBookingList] = useState([]);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [firstLoad, setFirstLoad] = useState(0);
    const [bookingId, setBookingId] = useState(0);
    const [collectionModal, setCollectionModal] = useState(false);
    const [detailsModal, setDetailsModal] = useState(false);
    const [detailsModalResult, setDetailsModalResult] = useState([]);
    const [filterDeliveryFromDate, setFilterDeliveryFromDate] = useState(pastYearDate);
    const [filterDeliveryToDate, setFilterDeliveryToDate] = useState(yesterdayDate);
    const [searchDeliveryFromDate, setSearchDeliveryFromDate] = useState(searchFromDate);
    const [searchDeliveryToDate, setSearchDeliveryToDate] = useState(searchToDate);

    const handleSearch = (val) => {
        setSearch(val);
        setActivePage(1);
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        if (pageNumber !== activePage) {
            setLoading(true);
            window.scrollTo({ top: 0 });
        }
    };

    const getList = async () => {
        setBookingList([]);
        setLoading(true);
        setFirstLoad(1);
        try {

            const res = await collectionBookingService.getList({
                bookedByFilter: "",
                hospitalFilter: "",
                type: ["loan"],
                status: ["dispatch", "close"],
                collectionDateFilter: filterDeliveryFromDate,
                toCollectionDateFilter: filterDeliveryToDate,
                search: search,
                page: activePage,
                limit: 50,
            });
            setBookingList(res.data);
            setTotalItemsCount(res.total_count);
            setLoading(false);
        } catch (error) {
            console.error("Error: ", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };


    const getXlsxData = async () => {
        setLoading(true);
        try {
            const res = await collectionBookingService.getBookingCollectionExport({
                bookedByFilter: "",
                hospitalFilter: "",
                type: ["loan"],
                status: ["dispatch", "close"],
                collectionDateFilter: filterDeliveryFromDate,
                toCollectionDateFilter: filterDeliveryToDate,
                search: search,
                page: activePage,
                limit: 50,
            });
            if (res.status === 200) {
                let dateInfo = "";
                dateInfo = "from " + moment(filterDeliveryFromDate).format("DD/MM/YYYY") + " to " + moment(filterDeliveryToDate).format("DD/MM/YYYY") + " ";
                let headingContent = "Collection other booking " + dateInfo;
                exportXls.handleExportClick(res?.data, headingContent);
            }
        } catch (error) {
            console.error("Error: ", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const handleSearchDeliveryFromDate = (val) => {
        setActivePage(1);
        setSearchDeliveryFromDate(val != null ? new Date(val) : "");
        setFilterDeliveryFromDate(val != null ? moment(val).format("YYYY-MM-DD") : "");
    };

    const handleSearchDeliveryToDate = (val) => {
        setActivePage(1);
        setSearchDeliveryToDate(val != null ? new Date(val) : "");
        setFilterDeliveryToDate(val != null ? moment(val).format("YYYY-MM-DD") : "");
    };


    const detailsModalOpen = (item) => {
        setDetailsModal(true);
        setDetailsModalResult(item);
    };

    const detailsModalClose = () => {
        setDetailsModal(false);
    };


    const collectionModalOpen = (id) => {
        setCollectionModal(true);
        setBookingId(id);
    };

    const collectionModalClose = () => {
        setCollectionModal(false);
        setBookingId(0);
    };
    const collectionSuccess = () => {
        setCollectionModal(false);
        setBookingId(0);
        setActivePage(1);
        if (activePage == 1) {
            getList();
        }
    };

    useDebounce(() => {
        if (firstLoad != 0)
            getList();
    }, [search], 800
    );

    useEffect(() => {
        getList();
    }, [activePage]);


    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem("user")));

    }, []);



    if (!user || user.data.user_role !== "admin") {
        return <Redirect to="/" />;
    }



    return (
        <div>
            <Navbar activePage="dashboard" />
            <main className="offset">
                <div className="container-fluid">
                    <div className="row pt-4">
                        <div className="col-lg-2 side-filters">
                            <span className="side-heading">Dashboard</span>
                            <DashboardSidebar
                                activePage="collection"
                                dispatchCount={totalItemsCount}
                            />
                        </div>
                        <div className="col-lg-10">
                            <div className="ground">
                                <div className="row">
                                    <div className="col-lg-4 col-md-12">
                                        <div className="sub-menu mb-0 mb-sm-3">
                                            <div
                                                className="btn-group"
                                                role="group"
                                                aria-label="Basic example">
                                                <Link
                                                    type="button"
                                                    className="btn btn-secondary "
                                                    to="/admin/dashboard/booking-collections"
                                                >
                                                    {"Today"}
                                                </Link>
                                                <Link
                                                    type="button"
                                                    className="btn btn-secondary mx-1"
                                                    to="/admin/dashboard/booking-collections/tomorrow">
                                                    {"Tomorrow"}
                                                </Link>

                                                <Link
                                                    type="button"
                                                    className="btn btn-secondary active btn-disabled"
                                                    to="/admin/dashboard/booking-collections/past" >
                                                    {"Other Bookings"}
                                                </Link>

                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-lg-4 col-sm-6">
                                        <div className="form-search">
                                            <Input
                                                className="form-control mr-sm-2"
                                                type="text"
                                                placeholder="Search.."
                                                value={search}
                                                onChange={e =>
                                                    handleSearch(
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                        </div>

                                    </div>

                                    <div className="col-lg-4 col-sm-6">
                                        <div className="label_adjust lable-field-position">
                                            <label className="small mb-0">Collection Date </label>
                                            <div className="row no-gutters mb-2">
                                                <div className="col-lg-4 col-sm-4 col-6 px-0">

                                                    <DatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        placeholderText="From"
                                                        selected={searchDeliveryFromDate}
                                                        onChange={(e) => handleSearchDeliveryFromDate(e)}
                                                        //onKeyDown={handleclearFromDate}
                                                        className="form-control"
                                                        disabled={!loading ? false : true}
                                                        customInput={
                                                            <MaskedInput
                                                                pipe={autoCorrectedDatePipe}
                                                                mask={[
                                                                    /\d/,
                                                                    /\d/,
                                                                    "/",
                                                                    /\d/,
                                                                    /\d/,
                                                                    "/",
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                ]}
                                                                keepCharPositions={true}
                                                                guide={true}
                                                                showMask={false}
                                                            />
                                                        }
                                                    />
                                                </div>

                                                <div className="col-lg-4 col-sm-4 col-6 mb-2 px-1">
                                                    <DatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        placeholderText="To"
                                                        selected={searchDeliveryToDate}
                                                        onChange={(e) => handleSearchDeliveryToDate(e)}
                                                        //onKeyDown={handleclearToDate}
                                                        className="form-control"
                                                        disabled={!loading ? false : true}
                                                        customInput={
                                                            <MaskedInput
                                                                pipe={autoCorrectedDatePipe}
                                                                mask={[
                                                                    /\d/,
                                                                    /\d/,
                                                                    "/",
                                                                    /\d/,
                                                                    /\d/,
                                                                    "/",
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                ]}
                                                                keepCharPositions={true}
                                                                guide={true}
                                                                showMask={false}
                                                            />
                                                        }
                                                    />
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-6">
                                                    <button type="button" className={!loading ? "btn btn-default add_option mx-2" : "btn mx-2 btn-default add_option dropdown-disabled"}
                                                        onClick={() => !loading && bookingList?.length ? getXlsxData() : ""}> Print </button></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-scroll mt-4">
                                    <Table className="table">
                                        <thead key={"thead"}>
                                            <tr>
                                                <th
                                                    scope="col"
                                                    style={{
                                                        minWidth: "200px",
                                                    }}>
                                                    Booking #
                                                </th>
                                                <th scope="col">
                                                    Hospital Name{" "}
                                                </th>
                                                <th scope="col">Set</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">
                                                    Dispatched Boxes
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-right"
                                                    style={{
                                                        maxWidth: "210px",
                                                    }}></th>
                                            </tr>
                                        </thead>
                                        <tbody key={"tbody"}>
                                            {bookingList &&
                                                bookingList.map(item => (
                                                    <tr key={item._id}>
                                                        <td
                                                            width="200"
                                                            data-th="Booking #"
                                                            className="align-top">
                                                            <Link
                                                                to={
                                                                    "/admin/booking/details/" +
                                                                    item._id
                                                                }>
                                                                <span>
                                                                    <strong>
                                                                        {" "}
                                                                        {
                                                                            item.booking_id
                                                                        }{" "}
                                                                    </strong>
                                                                </span>
                                                            </Link>
                                                        </td>
                                                        <td
                                                            data-th="Hospital Name"
                                                            className="align-top">
                                                            <div className="">
                                                                {
                                                                    item
                                                                        .hospitalId
                                                                        .name
                                                                }
                                                            </div>
                                                        </td>
                                                        <td
                                                            data-th="Set"
                                                            className="align-top">
                                                            <ul className="list">
                                                                {item.bookingKitSetId &&
                                                                    item.bookingKitSetId.map(
                                                                        (
                                                                            kset,
                                                                            ind,
                                                                        ) => (
                                                                            <React.Fragment
                                                                                key={
                                                                                    ind
                                                                                }>
                                                                                <li>
                                                                                    <span
                                                                                        className={
                                                                                            "status circle mr-1 " +
                                                                                            (kset.status ==
                                                                                                "close"
                                                                                                ? "green "
                                                                                                : "red ")
                                                                                        }
                                                                                        data-toggle="tooltip"
                                                                                        data-placement="top"
                                                                                        title=""
                                                                                        data-original-title="Retuned"></span>
                                                                                    <span>
                                                                                        {kset?.setId[0]?.set_name}
                                                                                    </span>
                                                                                </li>
                                                                            </React.Fragment>
                                                                        ),
                                                                    )}
                                                            </ul>
                                                        </td>
                                                        <td className="align-top">
                                                            {dateFormat(
                                                                item.collection_date,
                                                                "ddd,mmm dS",
                                                            )}
                                                        </td>
                                                        <td
                                                            data-th="Dispatched Boxes"
                                                            className="align-top">
                                                            {item.bookingdispatches &&
                                                                item.bookingdispatches[0].bookingdispatchboxes.map(
                                                                    (
                                                                        box,
                                                                        ind,
                                                                    ) => (
                                                                        <React.Fragment
                                                                            key={
                                                                                ind
                                                                            }>
                                                                            <div>
                                                                                {
                                                                                    box.box_type
                                                                                }{" "}
                                                                                -{" "}
                                                                                {
                                                                                    box.number_of_box
                                                                                }{" "}
                                                                                {box.number_of_box >
                                                                                    1
                                                                                    ? "Boxes"
                                                                                    : "Box"}
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ),
                                                                )}
                                                        </td>

                                                        <td className="align-top">
                                                            {item.status ==
                                                                "dispatch" &&
                                                                item
                                                                    .bookingcollections
                                                                    .length ==
                                                                0 && (
                                                                    <React.Fragment>
                                                                        <div style={{ display: "flex" }}>
                                                                            <a
                                                                                href="javascript:void(0)"
                                                                                onClick={() =>
                                                                                    collectionModalOpen(
                                                                                        item._id,
                                                                                    )
                                                                                }
                                                                                className="btn btn-primary ml-2">
                                                                                Collect
                                                                            </a>

                                                                            <a className="btn text-right" style={{ visibility: "hidden" }}>Details</a>
                                                                        </div>
                                                                    </React.Fragment>

                                                                )}
                                                            {item.status ==
                                                                "dispatch" &&
                                                                item
                                                                    .bookingcollections
                                                                    .length >
                                                                0 && (
                                                                    <React.Fragment>
                                                                        <span className="capsule green-dim">
                                                                            Collected
                                                                        </span>
                                                                        <a
                                                                            href="javascript:void(0)"
                                                                            onClick={() =>
                                                                                detailsModalOpen(
                                                                                    item.bookingcollections,
                                                                                )
                                                                            }
                                                                            className="btn ml-2">
                                                                            Details
                                                                        </a>
                                                                    </React.Fragment>
                                                                )}
                                                        </td>
                                                    </tr>
                                                ))}

                                            {loading == true &&
                                                <Norecord />

                                            }

                                            {bookingList &&
                                                bookingList.length == 0 && firstLoad != 0 &&
                                                loading == false && (
                                                    <tr className="text-center">
                                                        <td colSpan="7">
                                                            No Record Found
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>

                            {bookingList && bookingList.length > 0 && (
                                <div>
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={50}
                                        totalItemsCount={
                                            totalItemsCount
                                        }
                                        pageRangeDisplayed={5}
                                        onChange={handlePageChange.bind(
                                            this,
                                        )}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        innerClass="pagination justify-content-center mb-5"
                                        activeLinkClass="active"
                                        nextPageText="Next"
                                        prevPageText="Prev"
                                    />
                                    <br className="d-block d-sm-none" /><br className="d-block d-sm-none" />
                                </div>
                            )}

                        </div>
                    </div>
                    {/*<!--/row-->*/}
                </div>
            </main>

            <CollectionDetails
                bookingId={bookingId}
                detailsModal={detailsModal}
                detailsModalResult={detailsModalResult}
                detailsModalClose={() =>
                    detailsModalClose()
                }
            />

            <Collection
                bookingId={bookingId}
                collectionModal={collectionModal}
                collectionModalOpen={
                    collectionModalOpen
                }
                collectionModalClose={
                    collectionModalClose
                }
                collectionSuccess={collectionSuccess}
            />



            <Footer />
        </div>
    );
};
export default Index;

