import React from "react";
import { Link } from "react-router-dom";

const UsersNavbar = ({ activePage }) => {
  return (
    <div>
      <ul className="sub-menu">
        <li>
          <Link
            to="/admin/users"
            className={`kit-navbar ${
              activePage === "users" ? "active" : ""
            }`}
          >
            Active Users
          </Link>
        </li>
        <li>
          <Link
            to="/admin/archive"
            className={`kit-navbar ${
              activePage === "archive" ? "active" : ""
            }`}
          >
            Archive Users
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default UsersNavbar;
