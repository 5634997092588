import React, { createRef, Component } from "react";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import KitNavbar from "../Kits/KitNavbar";
import { Redirect } from "react-router-dom";
import { productActions } from "../../../../actions";
import { productService } from "../../../../services";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input } from "reactstrap";
import { ReactComponent as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";
import * as excelJs from "exceljs";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import downloadLink from "../../../../assets/images/SampleProductfile.xlsx";

const generateXLSX = async (data) => {
    const workbook = new excelJs.Workbook();
    const ws = workbook.addWorksheet("Test Worksheet");
    const header1 = [
        "Manufacturers Product Code",
        "Product Name",
        "Product GS1 Code",
        "Product Brand",
    ];
    const header1Row = ws.addRow(header1);
    header1Row.eachCell((cell) => {
        cell.font = {
            bold: true,
            size: 11,
        };
    });

    if (data !== []) {
        data.map(item => {
            const rowData = [
                item.manufacturers_product_code,
                item.name,
                item.code,
                item.product_brand,
            ];
            ws.addRow(rowData);
        });
    }
    ws.columns.forEach((col) => (col.width = 18));
    const excelBlob = await workbook.xlsx.writeBuffer();
    const excelUrl = URL.createObjectURL(
        new Blob([excelBlob], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
    );

    const link = document.createElement("a");
    link.href = excelUrl;
    link.download = "products";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(excelUrl);
    document.body.removeChild(link);
};

const generateDoublicateXLSX = async (data) => {
    const workbook = new excelJs.Workbook();
    const ws = workbook.addWorksheet("Test Worksheet");
    const header1 = [
        "Manufacturers Product Code"
    ];
    const header1Row = ws.addRow(header1);
    header1Row.eachCell((cell) => {
        cell.font = {
            bold: true,
            size: 11,
        };
    });

    if (data.length > 0) {
        data.map(item => {
            const rowData = [
                item
            ];
            ws.addRow(rowData);
        });
    }
    ws.columns.forEach((col) => (col.width = 28));
    const excelBlob = await workbook.xlsx.writeBuffer();
    const excelUrl = URL.createObjectURL(
        new Blob([excelBlob], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
    );

    const link = document.createElement("a");
    link.href = excelUrl;
    link.download = "duplicate-products";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(excelUrl);
    document.body.removeChild(link);
};

class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            addNewModal: false,
            editModal: false,
            deleteModal: false,
            name: "",
            code: "",
            manufacturers_product_code: "",
            product_brand: "",
            id: "",
            search: "",
            activePage: 1,
            limit: 50,
            totalItemsCount: 1,
            error: false,
            generationInProgress: false,
            dataXlsx: [],
            showImportModal: false,
            importInProgress: false,
        };

        this.fileInputRef = createRef();

        this.getProductsList = this.getProductsList.bind(this);
        this.addNewModalOpen = this.addNewModalOpen.bind(this);
        this.addNewModalClose = this.addNewModalClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.editModalOpen = this.editModalOpen.bind(this);
        this.editModalClose = this.editModalClose.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.deleteModalOpen = this.deleteModalOpen.bind(this);
        this.deleteModalClose = this.deleteModalClose.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.doDelete = this.doDelete.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleExportClick = this.handleExportClick.bind(this);
    }

    handlePageChange(pageNumber) {

        if(pageNumber !== this.state.activePage){
            this.setState(
                { 
                    activePage: pageNumber }
                , () => {
                    this.getProductsList();
                }
            );
            window.scrollTo({top: 0});
        }
    }

    openImportModal = () => {
        this.setState({ showImportModal: true });
    };

    handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            this.setState({ importInProgress: true });
            const formData = new FormData();
            formData.append("importFile", file);
            productService.importProducts(formData)
                .then((res) => {
                    alert(res.message);
                    // toast.success(res.message);

                })

                .catch((error) => {
                    error?.duplicateData ? generateDoublicateXLSX(error?.duplicateData) : "";
                    alert(error?.duplicateData ? "𝗘𝗿𝗿𝗼𝗿: " + error.common +"\n \n𝗗𝘂𝗽𝗹𝗶𝗰𝗮𝘁𝗲 𝗗𝗮𝘁𝗮: " + "Click 'Ok' button to download and view the list of duplicate products." : "𝗘𝗿𝗿𝗼𝗿: " + error.common +"\n");
                    // toast.error(`Error: ${error.common}`);

                })
                .finally(() => {
                    this.setState({ importInProgress: false }, () => {
                    });
                    event.target.value = null;
                });
        }
    };

    handleExportClick() {
        this.setState(
            {
                generationInProgress: true,
            });
        productService.exportProduct({ "search": "" })
            .then((response) => {
                generateXLSX(response.data)
                    .then(() => {
                        this.setState(
                            {
                                generationInProgress: false,
                            });
                    })
                    .catch((error) => {
                        console.log("Error generating Excel:", error);
                        this.setState(
                            {
                                generationInProgress: false,
                            });
                    });
            })
            .catch((error) => {
                console.log("export error", error);
            });
    }

    handlePageStates(activePage, totalItemsCount, limit) {

        this.setState(
            {
                activePage: activePage,
                totalItemsCount: totalItemsCount,
                limit: limit
            }
        );
    }

    getProductsList() {
        this.props.dispatch(productActions.getProductsList({ search: this.state.search, page: this.state.activePage, limit: this.state.limit }));
    }

    handleSearch(val) {
        this.setState({
            search: val,
            activePage: 1
        }, () => { this.getProductsList(); });

    }

    addNewModalOpen() {
        this.setState({ error: false, addNewModal: !this.state.addNewModal });
    }

    addNewModalClose() {
        this.setState({
            addNewModal: false,
            error: false,
            name: "",
            code: "",
            manufacturers_product_code: "",
            product_brand: ""
        });
    }

    codeHandler(val) {
        this.setState({
            code: val
        });
    }

    manufacturersProductCodeHandler(val) {
        this.setState({
            manufacturers_product_code: val
        });
    }

    productBrandHandler(val) {
        this.setState({
            product_brand: val
        });
    }

    nameHandler(val) {
        this.setState({
            name: val
        });
    }

    handleSubmit() {
        window.scrollTo({top: 0});
        this.props.dispatch(productActions.addProduct({ name: this.state.name, code: this.state.code, manufacturers_product_code: this.state.manufacturers_product_code, product_brand: this.state.product_brand }));
    }

    getSingleProduct(id) {
        this.props.dispatch(productActions.getById(id));
    }

    setEditProduct(data) {
        this.setState({
            code: data.code,
            manufacturers_product_code: data.manufacturers_product_code,
            product_brand: data.product_brand,
            name: data.name,
            id: data._id
        });
        this.editModalOpen();
    }

    editModalOpen() {
        this.setState({ error: false, editModal: !this.state.editModal });
    }

    editModalClose() {
        this.setState({
            editModal: false,
            error: false,
            name: "",
            code: "",
            manufacturers_product_code: "",
            product_brand: ""
        });
    }

    handleUpdate() {
        this.props.dispatch(productActions.updateProduct({ name: this.state.name, code: this.state.code, manufacturers_product_code: this.state.manufacturers_product_code, product_brand: this.state.product_brand, id: this.state.id }));
    }

    deleteModalOpen() {
        this.setState({ error: false, deleteModal: true });
    }

    deleteModalClose() {
        this.setState({
            deleteModal: false,
            error: false,
            name: "",
            code: ""
        });
    }

    handleDelete(id) {
        this.setState({
            id: id
        });
        this.deleteModalOpen();
    }

    doDelete() {
        window.scrollTo({top: 0});
        this.setState({ error: true });
        this.props.dispatch(productActions.delete({ id: this.state.id }));
    }

    componentDidMount() {
        this.getProductsList();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.refreshList == true) {
            this.setState({
                activePage: 1
            }, () => { this.getProductsList(); });
        }

        if (nextProps.activePage != "" && nextProps.totalItemsCount != "" && nextProps.limit != "") {
            this.handlePageStates(nextProps.activePage, nextProps.totalItemsCount, this.state.limit);
        }

        if (nextProps.modal == false) {
            this.addNewModalClose();
        }

        if (nextProps.editModal == true) {
            this.setEditProduct(nextProps.product);
        }

        if (nextProps.editModal == false) {
            this.editModalClose();
        }

        if (nextProps.deleteModal == false) {
            this.deleteModalClose();
        }

        if (nextProps.error !== "") {
            this.setState({
                error: true
            });

        }
    }

    render() {
        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if (this.state.user.data.user_role !== "admin") {
            return <Redirect to="/" />;
        }
        //const { loggingIn } = this.props;
        const { productsList, product, error, loading } = this.props;
        return (
            <div>
                {/* <ToastContainer /> */}
                <Navbar activePage="kits" />

                <div className="container">
                    {this.state.importInProgress && (
                        <div className="spinner-loader" ><div>
                            <Spinner className="img-svg loader-icon" />
                        </div>

                        </div>
                    )}

                    <div className="pt-4 row align-items-center">
                        <div className="col-md-4">
                            <div className="sub-menu">
                                <KitNavbar activePage="products" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-search my-1">
                                <Input
                                    className="form-control mr-sm-2"
                                    type="text"
                                    placeholder="Search.."
                                    value={this.state.search}
                                    onChange={(e) => this.handleSearch(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-4 text-right">
                            <label className="btn btn-default mr-2 mb-0 my-1">
                                Import{" "}
                                <input
                                    type="file"
                                    accept=".xlsx"
                                    ref={this.fileInputRef}
                                    style={{ display: "none" }}
                                    onChange={(e) => this.handleFileSelect(e)}
                                />
                            </label>

                            {this.state.showImportModal && (
                                <div className="modal" tabIndex="-1" role="dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Import Data</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                onClick={() => this.setState({ showImportModal: false })}
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-footer">
                                            <label className="btn btn-secondary mr-2" onClick={() => this.setState({ showImportModal: false })}>
                                                Close
                                            </label>
                                            <label className="btn btn-default add_option mr-2 mb-0">
                                                Import{" "}
                                                <input
                                                    type="file"
                                                    accept=".xlsx"
                                                    ref={this.fileInputRef}
                                                    style={{ display: "none" }}
                                                    // onChange={(e) => this.handleFileSelect(e)}
                                                    onChange={this.handleFileSelect}
                                                />

                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )}





                            <button type="button" onClick={() => this.handleExportClick()} className="btn btn-default mr-2 my-1"> Export </button>


                            <div className="float-right">
                                <button className="btn mr-2 btn-primary add_option" onClick={this.addNewModalOpen}><i className="fa fa-plus"></i> <span>Add New</span></button>
                            </div>

                            <div className="text-right mr-2">
                                <a href={downloadLink} target="_blank" rel="noopener noreferrer" download="SampleProductfile.xlsx">
                                Sample Product File
                                </a>
                            </div>

                        </div>
                        
                    </div>
                    <div id="table-scroll" className="table-scroll mt-4">
                        <table id="main-table" className="main-table">
                            <thead>
                                <tr>
                                    <th scope="col">Manufacturers Product Code</th>
                                    <th scope="col">Product Name</th>
                                    <th scope="col">Product Brand</th>
                                    <th scope="col" width="200">Product GS1 Code</th>
                                    <th scope="col" className="text-right" width="100">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {productsList && productsList.map((item) => (
                                    <tr key={item._id}>
                                        <td>
                                            {item.manufacturers_product_code}
                                        </td>
                                        <td>
                                            {item.name}
                                        </td>
                                        <td>
                                            {item.product_brand}
                                        </td>
                                        <td width="200">
                                            <div className="">
                                                <span><strong>{item.code}</strong></span>
                                            </div>
                                        </td>
                                        <td className="text-right">
                                            <div className="action-area dropdown">
                                                <a className="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span className="dots">...</span>
                                                </a>
                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <a className="dropdown-item" onClick={() => this.getSingleProduct(item._id)} href="javascript:;">Edit</a>
                                                    <a className="dropdown-item" onClick={() => this.handleDelete(item._id)} href="javascript:;">Delete</a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                {productsList && productsList.length == 0 && loading == false &&
                                    <tr className="text-center">
                                        <td colSpan="3">No Record Found</td>
                                    </tr>
                                }

                                { productsList && productsList.length == 0 && loading == true &&  
                                    <tr className="text-center">
                                        <td colSpan="5">
                                            <div className="loading-state-new">
                                                <div className="loading"></div>
                                            </div>                                               
                                        </td>
                                    </tr>                                                                
                                }

                            </tbody>
                        </table>
                    </div>
                </div>

                {productsList && productsList.length > 0 &&
                    <div>
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.limit}
                            totalItemsCount={this.state.totalItemsCount}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                            innerClass="pagination justify-content-center mb-5"
                            activeLinkClass="active"
                            nextPageText="Next"
                            prevPageText="Prev"
                        />
                        <br className="d-block d-sm-none" /><br className="d-block d-sm-none" />
                    </div>
                }

                <Modal size="md" backdrop="static" isOpen={this.state.addNewModal} toggle={() => this.addNewModalClose()}>
                    <ModalHeader className="" toggle={() => this.addNewModalClose()}>
                        Add New Product
                    </ModalHeader>
                    <ModalBody>
                        <form className="">
                            <div className="form-group">
                                <label>Manufacturers Product Code</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={this.state.manufacturers_product_code}
                                    onChange={(e) => this.manufacturersProductCodeHandler(e.target.value)}
                                />
                                {(this.state.error && error) ? <span className="text-danger">{error.manufacturers_product_code ? error.manufacturers_product_code : ""}</span> : ""}
                            </div>
                            <div className="form-group">
                                <label>Product Name</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={this.state.name}
                                    onChange={(e) => this.nameHandler(e.target.value)}
                                />
                                {(this.state.error && error) ? <span className="text-danger">{error.name ? error.name : ""}</span> : ""}
                            </div>
                            <div className="form-group">
                                <label>Product Brand</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={this.state.product_brand}
                                    onChange={(e) => this.productBrandHandler(e.target.value)}
                                />
                                {(this.state.error && error) ? <span className="text-danger">{error.product_brand ? error.product_brand : ""}</span> : ""}
                            </div>
                            <div className="form-group">
                                <label>Product GS1 Code</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={this.state.code}
                                    onChange={(e) => this.codeHandler(e.target.value)}
                                />
                                {(this.state.error && error) ? <span className="text-danger">{error.code ? error.code : ""}</span> : ""}
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button onClick={() => this.addNewModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                            {loading == false &&
                                <button className="btn btn-primary" onClick={this.handleSubmit}>Submit</button>
                            }
                            {loading == true &&
                                <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>

                            }
                        </div>
                    </ModalFooter>
                </Modal>
                {/* Add Modal */}

                <Modal size="md" backdrop="static" isOpen={this.state.editModal} toggle={() => this.editModalClose()}>
                    <ModalHeader className="" toggle={() => this.editModalClose()}>
                        Edit Product
                    </ModalHeader>
                    <ModalBody>
                        <form className="">
                            {product &&
                                <div>
                                    <div className="form-group">
                                        <label>Manufacturers Product Code</label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            value={this.state.manufacturers_product_code}
                                            onChange={(e) => this.manufacturersProductCodeHandler(e.target.value)}
                                        />
                                        {(this.state.error && error) ? <span className="text-danger">{error.manufacturers_product_code ? error.manufacturers_product_code : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>Product Name</label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            value={this.state.name}
                                            onChange={(e) => this.nameHandler(e.target.value)}
                                        />
                                        {(this.state.error && error) ? <span className="text-danger">{error.name ? error.name : ""}</span> : ""}
                                        {(this.state.error && error) ? <span className="text-danger">{error.common ? error.common : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>Product Brand</label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            value={this.state.product_brand}
                                            onChange={(e) => this.productBrandHandler(e.target.value)}
                                        />
                                        {(this.state.error && error) ? <span className="text-danger">{error.product_brand ? error.product_brand : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>Product GS1 Code</label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            value={this.state.code}
                                            onChange={(e) => this.codeHandler(e.target.value)}
                                        />
                                        {(this.state.error && error) ? <span className="text-danger">{error.code ? error.code : ""}</span> : ""}
                                    </div>
                                </div>
                            }
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button onClick={() => this.editModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                            {loading == false &&
                                <button className="btn btn-primary" onClick={this.handleUpdate}>Update</button>
                            }
                            {loading == true &&
                                <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>

                            }
                        </div>
                    </ModalFooter>
                </Modal>
                {/* Edit Modal */}

                <Modal size="md" backdrop="static" isOpen={this.state.deleteModal} toggle={() => this.deleteModalClose()}>
                    <ModalHeader className="" toggle={() => this.deleteModalClose()}>
                        Delete Product
                    </ModalHeader>
                    <ModalBody>

                        <div className="my-2">Please confirm if you want to delete this product?</div>
                        {(this.state.error && error) ? <span className="text-danger">{error.common ? error.common : ""}</span> : ""}
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button onClick={() => this.deleteModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                            {loading == false &&
                                <button className="btn btn-primary" onClick={this.doDelete}>Ok</button>
                            }
                            {loading == true &&
                                <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>

                            }
                        </div>
                    </ModalFooter>
                </Modal>
                {/* Delete confirmation */}
                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { productsList, activePage, totalItemsCount, limit } = state.rootReducer.products;
    const { product, error, modal, editModal, loading, refreshList, deleteModal } = state.rootReducer.products;

    return {
        productsList,
        product,
        error,
        modal,
        editModal,
        loading,
        refreshList,
        deleteModal,
        activePage,
        totalItemsCount,
        limit
    };
}

export default connect(mapStateToProps)(Index);
