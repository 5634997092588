import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Input, Table } from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import { repsBookingService } from "../../../../services/repsBooking.services";
import Select from "react-select";
import useDebounce from "../../../../helpers/UseDebounce";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import dateFormat from "dateformat";
import CancelModal from "./Modal/Cancle";
import Norecord from "./Past/Norecord";
const statusOptions = [
    { value: "", label: "All" },
    { value: "yes", label: "Filled" },
    { value: "no", label: "Empty" },
];

let newDate = new Date();
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let separator = "-";
const todayDate = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
    }${separator}${date < 10 ? `0${date}` : `${date}`}`;

const RepsItemUsedList = () => {
    const [redirect, setRedirect] = useState(false);
    const [search, setSearch] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [totalItemsCount, setTotalItemsCount] = useState("");
    const [loading, setLoading] = useState(false);
    const [bookingList, setBookingList] = useState([]);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [status, setStatus] = useState("");
    const [selectedStatus, setSelectedStatus] = useState([{ value: "", label: "All" }]);//{ value: "", label: "All" }
    const [cancelModal, setCancelModal] = useState(false);
    const [cancelId, setCancelId] = useState(0);
    const [firstLoad, setFirstLoad] = useState(0);



    const handleSearch = (val) => {
        setSearch(val);
        setActivePage(1);
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        if (pageNumber !== activePage) {
            setLoading(true);
            window.scrollTo({ top: 0 });
        }
    };

    const getList = async () => {
        setBookingList([]);
        setLoading(true);
        setFirstLoad(1);
        try {
            const res = await repsBookingService.getBookingForDashboard({
                working_delivery_from_date: "",
                type: ["loan"],
                status: ["pending", "booked", "dispatch", "return", "close"],
                operation_from_date: todayDate,
                operation_to_date: todayDate,
                search: search,
                page: activePage,
                limit: 50,
                reps_id: user?.data?._id,
                itemUsedProduct_filter: status
            });
            setBookingList(res.data);
            setTotalItemsCount(res.total_count);
            setLoading(false);
        } catch (error) {
            console.error("Error: ", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const handleStatus = (e) => {
        setActivePage(1);
        setStatus(e.value);
        setSelectedStatus(e);
    };

    const cancelModalOpen = (id) => {
        setCancelId(id);
        setCancelModal(true);
    };

    const cancelModalClose = () => {
        setCancelId(0);
        setCancelModal(false);
    };

    const refreshGetList = () => {
        setCancelId(0);
        setCancelModal(false);
        setActivePage(1);
        setLoading(true);
        window.scrollTo({ top: 0 });
        if (activePage == 1) {
            getList();
        }
    };


    useDebounce(() => {
        if (firstLoad != 0)
            getList();
    }, [search], 800
    );

    useEffect(() => {
        getList();
    }, [activePage, status]);


    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem("user")));

    }, []);


    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };


    if (!user || user.data.user_role !== "reps") {
        return <Redirect to="/" />;
    }

    const handleFocus = () => {
        setRedirect(true);
    };

    if (redirect) {
        return <Redirect to="/reps/item-used/all" />;
    }

    return (
        <div>
            <Navbar activePage="item-used" />
            <main className="offset">
                <div className="container-fluid">
                    <div className="row pt-4">
                        {/* <div className="col-lg-2 side-filters">
                            <span className="side-heading">Dashboard</span>
                        </div> */}

                        <div className="col-lg-12">
                            <div className="ground">
                                <div className="row">
                                    <div className="col-lg-4 col-md-12 mb-2">
                                        <div className="sub-menu mb-0 mb-sm-3">
                                            <div
                                                className="btn-group"
                                                role="group"
                                                aria-label="Basic example">
                                                <Link
                                                    type="button"
                                                    className="btn btn-secondary active btn-disabled"
                                                    to="/reps/item-used"
                                                >
                                                    {"Today"}
                                                </Link>
                                                <Link
                                                    type="button"
                                                    className="btn btn-secondary mx-1"
                                                    to="/reps/item-used/tomorrow">
                                                    {"Tomorrow"}
                                                </Link>

                                                <Link
                                                    type="button"
                                                    className="btn btn-secondary"
                                                    to="/reps/item-used/past" >
                                                    {"Past Bookings"}
                                                </Link>

                                                <Link
                                                    type="button"
                                                    className="btn btn-secondary mx-1"
                                                    to="/reps/item-used/all" >
                                                    {"All Bookings"}
                                                </Link>

                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-lg-3 col-sm-6 mb-2">
                                        <div className="form-search">
                                            <Input
                                                className="form-control mr-sm-2"
                                                type="text"
                                                placeholder="Search.."
                                                value={search}
                                                onFocus={handleFocus}
                                                onChange={e =>
                                                    handleSearch(
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-5 col-sm-6 mb-2">
                                        {/* <div className="d-flex justify-content-end" style={{gap:"10px"}}> */}
                                        {/* icon code required */}
                                        <div className="">
                                            <React.Fragment>

                                                <div className="row no-gutters mb-2">
                                                    <div className="col-lg-7 col-0"></div>
                                                    <div className="col-lg-4 col-12">
                                                        <div className="label_adjust">
                                                            <label className="small mb-0">
                                                                Item Used{" "}
                                                            </label>
                                                            <Select
                                                                placeholder="Item Used"
                                                                value={selectedStatus}
                                                                onChange={handleStatus}
                                                                options={statusOptions}
                                                                className="text-left"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>

                                        </div>
                                    </div>
                                </div>
                                <div className="table-scroll mt-4">
                                    <Table className="table">
                                        <thead>
                                            <tr>
                                                <th
                                                    scope="col"
                                                    style={{
                                                        minWidth: "200px",
                                                    }}>
                                                    Booking #
                                                </th>
                                                <th scope="col">
                                                    Hospital Name{" "}
                                                </th>
                                                <th scope="col">
                                                    Operation Name{" "}
                                                </th>
                                                <th scope="col" className="mobilehide">
                                                    Kits Required{" "}
                                                </th>
                                                <th scope="col">
                                                    Sets{" "}
                                                </th>
                                                <th scope="col">
                                                    Item Used{" "}
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-manage">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bookingList &&
                                                bookingList.map((item, index) => (
                                                    <tr key={item._id + "-" + index}>
                                                        <td
                                                            width="200"
                                                            data-th="Booking #"
                                                            className="">
                                                            <Link
                                                                to={
                                                                    "/reps/booking/details/" +
                                                                    item
                                                                        .bookings
                                                                        ._id
                                                                }>
                                                                <span>
                                                                    <strong>
                                                                        {" "}
                                                                        {
                                                                            item
                                                                                .bookings
                                                                                .booking_id
                                                                        }{" "}
                                                                    </strong>
                                                                </span>
                                                            </Link>
                                                        </td>
                                                        <td className="" data-th="Hospital Name">
                                                            {
                                                                item
                                                                    .hospitalId
                                                                    .name
                                                            }
                                                        </td>
                                                        <td data-th="Operation Name" className="">
                                                            <div>
                                                                {item.bookings.operation_name}
                                                            </div>
                                                        </td>

                                                        <td
                                                            data-th="Kits Required"
                                                            className="mobilehide">
                                                            <small>
                                                                {item.bookingkits &&
                                                                    item.bookingkits.map(
                                                                        (
                                                                            kit,
                                                                            ind,
                                                                        ) => (
                                                                            <React.Fragment
                                                                                key={
                                                                                    ind
                                                                                }>
                                                                                <div>
                                                                                    <span>
                                                                                        {
                                                                                            kit
                                                                                                .kits[0]
                                                                                                .name
                                                                                        }{" "}
                                                                                    </span>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        ),
                                                                    )}
                                                            </small>
                                                        </td>
                                                        <td
                                                            data-th="Sets"
                                                            className="mobilehide">
                                                            <small>
                                                                {item.bookingKitSetId &&
                                                                    item.bookingKitSetId.map(
                                                                        (
                                                                            kset,
                                                                            ind,
                                                                        ) => (
                                                                            <React.Fragment
                                                                                key={
                                                                                    ind
                                                                                }>
                                                                                <div>
                                                                                    <span>
                                                                                        {
                                                                                            kset
                                                                                                .setId[0]
                                                                                                .set_name
                                                                                        }{" "}
                                                                                    </span>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        ),
                                                                    )}
                                                            </small>
                                                        </td>

                                                        <td data-th="Filled Status"
                                                        >
                                                            <span className={
                                                                "capsule " +
                                                                (item.itemUsedProducts.length > 0
                                                                    ? "green-dim "
                                                                    : "red-dim ") +
                                                                " mr-2 mb-1"
                                                            }>
                                                                {item.itemUsedProducts.length > 0 ? "Filled" : "Empty"}
                                                            </span>
                                                        </td>

                                                        <td className="text-manage">
                                                            <div className="action-area dropdown">
                                                                <div className="mb-2">
                                                                    <span
                                                                        className={
                                                                            "capsule " +
                                                                            (item
                                                                                .bookings
                                                                                .status ==
                                                                                "booked" && dateFormat(item.bookings.working_delivery_date, "yyyy-mm-dd") != dateFormat(new Date(), "yyyy-mm-dd")
                                                                                || item.bookings.status === "close"
                                                                                || item.bookingcollections.length && item.bookingreturns.length === 0
                                                                                || item.bookings.status == "dispatch" && item.bookingcollections.length === 0 && item.bookingreturns.length === 0
                                                                                ? "green-dim "
                                                                                : "red-dim ") +
                                                                            " mr-2 mb-1"
                                                                        }>
                                                                        {item
                                                                            .bookings
                                                                            .status ==
                                                                            "dispatch" && (
                                                                                <div>
                                                                                    {item.bookingcollections.length && item.bookingreturns.length === 0 ? "Collected" : item.bookingreturns.length > 0 ? "Attempting Return (Partial)" : "Dispatched"}

                                                                                </div>
                                                                            )}
                                                                        {item
                                                                            .bookings
                                                                            .status !=
                                                                            "dispatch" &&
                                                                            capitalize(
                                                                                item.bookings.status === "booked" && dateFormat(item.bookings.working_delivery_date, "yyyy-mm-dd") === dateFormat(new Date(), "yyyy-mm-dd") ? "Pending Dispatch" : item.bookings.status === "booked" ?
                                                                                    "Booked and Confirmed" :
                                                                                    item.bookings.status === "pending" ? "Booked and not confirmed" :
                                                                                        item.bookings.status === "close" ? "Fully Return" : item.bookings.status
                                                                            )}
                                                                    </span>
                                                                </div>

                                                                <div className="mb-2">
                                                                    <span>
                                                                        <Link
                                                                            className="btn btn-outline-primary btn-sm1"
                                                                            to={
                                                                                "/reps/booking/details/" +
                                                                                item
                                                                                    .bookings
                                                                                    ._id
                                                                            }>
                                                                            More Details
                                                                        </Link>
                                                                    </span>
                                                                </div>


                                                                {(item.bookings
                                                                    .status ===
                                                                    "pending" ||
                                                                    item
                                                                        .bookings
                                                                        .status ===
                                                                    "booked") && (
                                                                        <div className="mb-2">
                                                                            <span>
                                                                                <Link
                                                                                    className="btn btn-sm1 btn-outline-primary"
                                                                                    to={
                                                                                        "/reps/booking/edit/" +
                                                                                        item
                                                                                            .bookings
                                                                                            ._id
                                                                                    }>
                                                                                    Edit
                                                                                </Link>
                                                                            </span>
                                                                        </div>
                                                                    )}

                                                                {item.bookings
                                                                    .status ===
                                                                    "pending" && (
                                                                        <div className="mb-2">
                                                                            <span>
                                                                                <a
                                                                                    href="javascript:;"
                                                                                    onClick={() =>
                                                                                        cancelModalOpen(
                                                                                            item
                                                                                                .bookings
                                                                                                ._id,
                                                                                        )
                                                                                    }
                                                                                    className="btn btn-outline-primary btn-sm1">
                                                                                    Cancel
                                                                                </a>
                                                                            </span>
                                                                        </div>
                                                                    )}

                                                                {item.bookings
                                                                    .status ===
                                                                    "booked" &&
                                                                    dateFormat(
                                                                        item
                                                                            .bookings
                                                                            .delivery_date,
                                                                        "yyyy-mm-dd",
                                                                    ) >
                                                                    dateFormat(
                                                                        new Date(),
                                                                        "yyyy-mm-dd",
                                                                    ) && (
                                                                        <div className="mb-2">
                                                                            <span>
                                                                                <a
                                                                                    href="javascript:;"
                                                                                    onClick={() =>
                                                                                        cancelModalOpen(
                                                                                            item
                                                                                                .bookings
                                                                                                ._id,
                                                                                        )
                                                                                    }
                                                                                    className="btn btn-outline-primary btn-sm1">
                                                                                    Cancel
                                                                                </a>
                                                                            </span>
                                                                        </div>
                                                                    )}

                                                                {item.bookings
                                                                    .status ==
                                                                    "dispatch" && (
                                                                        <div className="mb-2">
                                                                            <span>
                                                                                <Link
                                                                                    className="btn btn-outline-primary btn-sm1"
                                                                                    to={
                                                                                        "/reps/booking/item-used/o/" +
                                                                                        item
                                                                                            .bookings
                                                                                            ._id
                                                                                    }>
                                                                                    Item
                                                                                    Used
                                                                                </Link>
                                                                            </span>
                                                                        </div>
                                                                    )}

                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}

                                            {loading == true &&
                                                <Norecord />

                                            }

                                            {bookingList &&
                                                bookingList.length == 0 && firstLoad != 0 &&
                                                loading == false && (
                                                    <tr className="text-center">
                                                        <td colSpan="7">
                                                            No Record Found
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>

                            {bookingList && bookingList.length > 0 && (
                                <div>
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={50}
                                        totalItemsCount={
                                            totalItemsCount
                                        }
                                        pageRangeDisplayed={5}
                                        onChange={handlePageChange.bind(
                                            this,
                                        )}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        innerClass="pagination justify-content-center mb-5"
                                        activeLinkClass="active"
                                        nextPageText="Next"
                                        prevPageText="Prev"
                                    />
                                    <br className="d-block d-sm-none" /><br className="d-block d-sm-none" />
                                </div>
                            )}

                            {/* Dispatch Details Modal */}
                        </div>
                    </div>
                    {/*<!--/row-->*/}
                </div>
            </main>

            <CancelModal
                cancelId={cancelId}
                cancelModal={cancelModal}
                cancelModalClose={cancelModalClose}
                refreshGetList={refreshGetList}
            />



            <Footer />
        </div>
    );
};
export default RepsItemUsedList;

