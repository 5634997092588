import React, { useEffect, useMemo } from "react";
import useState from "react-usestateref";
import { Link } from "react-router-dom";
import { Input, UncontrolledTooltip } from "reactstrap";
//import Pagination from "react-js-pagination";
import Footer from "../../../../GlobalComponents/Footer";
import Navbar from "../../Navbar";
import FilterTooltip from "./Filter";
import {
  bookingService,
  kitService,
  surgeonService,
  operationService,
} from "../../../../../services";
import dateFormat from "dateformat";
import moment from "moment";
import * as XLSX from "xlsx";
import { ExportSheet } from "react-xlsx-sheet";
import DatePicker from "react-datepicker";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import { ReactComponent as FilterIcon } from "../../../../../assets/images/filter.svg";
import InfinitScroll from "react-infinite-scroll-component";
import { debounce } from "lodash";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Norecord from "../Norecord";
import * as excelJs from "exceljs";

const head = [
  { title: "Id", dataIndex: "Id" },
  { title: "Hospital Name", dataIndex: "Hospital Name" },
  { title: "Dispatch Date", dataIndex: "Dispatch Date" },
  { title: "Delivery Time", dataIndex: "Delivery Time" },
  { title: "Operation Date", dataIndex: "Operation Date" },
  { title: "Slot", dataIndex: "Slot" },
  { title: "Notes", dataIndex: "Notes" },
  { title: "Kit", dataIndex: "Kit" },
];

const autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/MM/yyyy");

const Index = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [newBookingList, setNewBookingList] = useState([]);
  const [oldBookingList, setOldBookingList] = useState([]);
  const [search, setSearch] = useState("");
  const [todayDate, setTodayDate] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [loading, setLoading] = useState(false);
  const [dataXlsx, setDataXlsx] = useState([]);
  const [filterOperationToDate, setfilterOperationToDate] = useState("");
  const [
    filterOperationFromDate,
    setfilterOperationFromDate,
    filterOperationFromDateRef,
  ] = useState(moment().format("YYYY-MM-DD"));
  const [filterDeliveryFromDate, setfilterDeliveryFromDate] = useState("");
  const [searchDeliveryFromDate, setsearchDeliveryFromDate] = useState("");
  const [searchOperationFromDate, setsearchOperationFromDate] = useState(
    new Date()
  );
  const [selectedStatus, setSelectedStatus, selectedStatusRef] = useState("");
  const [searchOperationToDate, setsearchOperationToDate] = useState("");
  const [filterModal, setFilterModal] = useState(false);
  const [filterPoChase, setFilterPoChase, filterPoChaseRef] = useState("all");
  const [filterCreateDate, setFilterCreateDate, filterCreateDateRef] =
    useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [totalItemsCount, setTotalItemsCount, totalItemsCountRef] = useState(0);
  const [hospitals, setHospitalList] = useState([]);
  const [hospitalId, setHospitalId, hospitalIdRef] = useState("");
  const [selectedHospital, setSelectedHospital] = useState([
    { label: "All Hospital", value: "" },
  ]);
  const [kits, setKits] = useState([]);
  const [selectedKit, setSelectedKit] = useState([]);
  const [selectdKitValue, setSelectdKitValue, selectdKitValueRef] =
    useState("");
  const [surgeons, setSurgeons] = useState([]);
  const [selectedSurgeon, setSelectedSurgeon] = useState([
    { label: "All Surgeon", value: "" },
  ]);
  const [surgeonId, setSurgeonId, surgeonIdRef] = useState("");
  const [operationsList, setOperationsList] = useState([]);
  const [selectedOperation, setSelectedOperation] = useState([
    { label: "All Operation", value: "" },
  ]);
  const [operationId, setOperationId, operationIdRef] = useState("");
  const [selectdSetValue, setSelectdSetValue, selectdSetValueRef] =
    useState("");

  const handleFilterToggle = () => {
    setFilterModal(!filterModal);
  };

  const handleFilterRefreesh = (data) => {
    if (data !== undefined) {
      setDataXlsx([]);
      setNewBookingList([]);
      setOldBookingList([]);

      if (
        filterPoChaseRef.current === data?.filterPoChase &&
        filterCreateDateRef.current === data?.filterCreateDate &&
        hospitalIdRef.current === data?.hospitalId &&
        selectdKitValueRef.current === data?.selectdKitValue &&
        surgeonIdRef.current === data?.surgeonId &&
        operationIdRef.current === data?.operationId &&
        selectdSetValueRef.current === data?.selectdSetValue &&
        selectedStatusRef.current === data?.selectedStatus
      ) {
        getList(1);
      }

      setActivePage(1);
      setFilterModal(!filterModal);
      setFilterPoChase(data?.filterPoChase);
      setFilterCreateDate(data?.filterCreateDate);
      setHospitalId(data?.hospitalId);
      setSelectedHospital(data?.selectedHospitalProp);
      setSelectdKitValue(data?.selectdKitValue);
      setSelectedKit(data?.selectedKitProp);
      setSurgeonId(data?.surgeonId);
      setSelectedSurgeon(data?.selectedSurgeonProp);
      setOperationId(data?.operationId);
      setSelectedOperation(data?.selectedOperationProp);
      setSelectdSetValue(data?.selectdSetValue);
      setSelectedStatus(data?.selectedStatus);
    }
  };

  const getList = async (flag, searchTerm, currentPage) => {
    if (currentPage === true) {
      setActivePage(activePage);
    } else {
      setActivePage(flag ? 1 : activePage + 1);
    }
    setLoading(true);

    try {
      const res = await bookingService.getBookingList({
        operation_from_date: filterOperationFromDateRef.current,
        operation_to_date: filterOperationToDate,
        type: ["loan"],
        status:
					selectedStatusRef.current == ""
						? ["booked", "dispatch", "return","close"]
						: [selectedStatusRef.current],
        working_delivery_from_date: filterDeliveryFromDate,
        search: searchTerm != undefined ? searchTerm : search,
        page: flag ? 1 : activePage + 1,
        limit: limit,
        po_check: filterPoChaseRef.current,
        created_date: filterCreateDateRef.current,
        hospitalFilter: hospitalIdRef.current,
        kitFilter: selectdKitValueRef.current,
        surgeonFilter: surgeonIdRef.current,
        operationFilter: operationIdRef.current,
        setFilter: selectdSetValueRef.current,
      });
      if (res) {
        arrangeBookingList(res.data, flag);
        setTotalItemsCount(res?.total_count);

        setLoading(false);
      }
    } catch (error) {
      console.error("Error: ", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (val) => {
    setDataXlsx([]);
    setNewBookingList([]);
    setOldBookingList([]);
    setSearch(val.target.value);
    setActivePage(1);
    getList(1, val.target.value);
  };

  const debouncedSearch = useMemo(() => {
    return debounce(handleSearch, 1500);
  }, [search]);

  const handleSearchDeliveryFromDate = (val) => {
    setDataXlsx([]);
    setNewBookingList([]);
    setOldBookingList([]);
    setActivePage(0);
    setsearchDeliveryFromDate(val != null ? new Date(val) : "");
    setfilterDeliveryFromDate(
      val != null ? moment(val).format("YYYY-MM-DD") : ""
    );
  };

  const handleSearchOperationFromDate = (val) => {
    setDataXlsx([]);
    let currentDeliveryFromDate =
      val != null ? moment(val).format("YYYY-MM-DD") : "";
    if (currentDeliveryFromDate != filterOperationFromDateRef.current) {
      setNewBookingList([]);
      setOldBookingList([]);
      setActivePage(0);
    }

    setsearchOperationFromDate(val != null ? new Date(val) : "");
    setfilterOperationFromDate(
      val != null ? moment(val).format("YYYY-MM-DD") : ""
    );
  };

  const handleSearchOperationToDate = (val) => {
    setDataXlsx([]);
    setNewBookingList([]);
    setOldBookingList([]);
    setActivePage(0);
    setsearchOperationToDate(val != null ? new Date(val) : "");
    setfilterOperationToDate(
      val != null ? moment(val).format("YYYY-MM-DD") : ""
    );
  };

  const handleSearchDeliveryDate = () => {
    setDataXlsx([]);
    if (
      filterOperationFromDate === "" &&
      filterOperationToDate === "" &&
      filterDeliveryFromDate === ""
    ) {
      getList(1, "");
    }

    document.getElementById("searchInput").value = "";
    setNewBookingList([]);
    setOldBookingList([]);
    setActivePage(0);
    setSearch("");
    setsearchOperationFromDate("");
    setsearchOperationToDate("");
    setsearchDeliveryFromDate("");
    setfilterOperationFromDate("");
    setfilterOperationToDate("");
    setfilterDeliveryFromDate("");
  };

  const arrangeBookingList = (bookingList, flag) => {
    let allBookingList = [];
    var todayDate = "";
    allBookingList = flag ? bookingList : oldBookingList.concat(bookingList);
    setOldBookingList(allBookingList);
    setNewBookingList(allBookingList);
    setTodayDate(todayDate);
  };

  const showXlsData = (data) => {
    var todayIndex = [];
    data.map((item, index) => {
      var todayDate = moment().format("DD/MM/YYYY");
      if (
        moment(item.operation_date).format("DD/MM/YYYY") == todayDate
      ) {
        todayIndex.push(index);
      }
    });
    generateTemplate(data);
    setIsLoading(false);
  };

  const getSurgeonListDropdown = async () => {
    try {
      const res = await surgeonService.getSurgeonsList({ search: "" });

      let surgeonList = res?.data ? res?.data : [];
      let tempSurgeonList = [{ label: "All Surgeon", value: "" }];
      surgeonList &&
        surgeonList.map((item) => {
          let option = {
            value: item._id,
            label: item.first_name + " " + item.last_name,
            notes: item?.notes ? item?.notes : "",
          };
          tempSurgeonList.push(option);
        });
      setSurgeons(tempSurgeonList);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const searchKeyUp = (e) => {
    let keyCodeArray = [
      8, 13, 16, 17, 18, 20, 27, 37, 39, 45, 46, 113, 114, 115, 117, 118, 119,
      120, 121, 122, 123, 132,
    ];
    let flag = keyCodeArray.includes(e.keyCode);
    if (e.altKey || e.ctrlKey) {
      return true;
    } else if (!flag) {
      setLoading(true);
      setNewBookingList([]);
      setOldBookingList([]);
    }
  };

  useEffect(() => {
    getHospitalList();
    getKitListDropdown();
    getSurgeonListDropdown();
    getOperationListDropdown();
    setUser(JSON.parse(localStorage.getItem("user")));
    console.log(
      setLimit,
      filterOperationFromDate,
      hospitalId,
      selectdKitValue,
      surgeonId,
      operationId,
      selectdSetValue
    );
    handleSearchOperationFromDate(moment().toISOString());
  }, []);

  useEffect(() => {
    getList(1);
  }, [
    filterOperationFromDateRef.current,
    filterCreateDateRef.current,
    filterPoChaseRef.current,
    filterOperationToDate,
    filterDeliveryFromDate,
    hospitalIdRef.current,
    selectdKitValueRef.current,
    surgeonIdRef.current,
    operationIdRef.current,
    selectdSetValueRef.current,
    selectedStatusRef.current,
  ]);

  useEffect(() => {
    console.log(filterPoChase, selectedStatus, filterCreateDate, totalItemsCount, todayDate);
  }, []);

  const getXlsxData = async () => {
    setIsLoading(true);
    try {
      const res = await bookingService.getBookingsExport({
        operation_from_date: filterOperationFromDateRef.current,
        operation_to_date: filterOperationToDate,
        type: ["loan"],
        status: ["booked", "dispatch", "return","close"],
        working_delivery_from_date: filterDeliveryFromDate,
        search: search,
        page: 1,
        limit: totalItemsCountRef.current,
        po_check: filterPoChaseRef.current,
        created_date: filterCreateDateRef.current,
        hospitalFilter: hospitalIdRef.current,
        kitFilter: selectdKitValueRef.current,
        surgeonFilter: surgeonIdRef.current,
        operationFilter: operationIdRef.current,
        setFilter: selectdSetValueRef.current,
      });
      if (res.status) {
        showXlsData(res?.data);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const getHospitalList = async () => {
    try {
      const res = await bookingService.getHospitalsList({ search: "" });
      if (res.status === 200) {
        showHospitalsList(res?.data);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const showHospitalsList = (hospitalsList) => {
    let tempHospitals = [{ label: "All Hospital", value: "" }];
    hospitalsList.map((item) => {
      let option = { value: item._id, label: item.name };
      tempHospitals.push(option);
    });
    setHospitalList(tempHospitals);
  };

  const getKitListDropdown = async () => {
    try {
      const res = await kitService.kitListDropdown({ search: "" });

      let kitList = res?.data ? res?.data : [];
      let tempKitList = [];

      kitList?.length &&
        kitList.map((item) => {
          var option = { value: item._id, label: item.name };
          tempKitList.push(option);
        });

      setKits(tempKitList);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const getOperationListDropdown = async () => {
    try {
      const res = await operationService.getOperationsList({ search: "" });

      let operationList = res?.data ? res?.data : [];
      let tempOperationList = [{ label: "All Operation", value: "" }];
      operationList &&
        operationList.map((item) => {
          let option = { value: item.name, label: item.name, id: item._id };
          tempOperationList.push(option);
        });
      setOperationsList(tempOperationList);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const addNewlines = (string, interval) => {
    let result = "";
    for (let i = 0; i < string.length; i++) {
      if (i > 0 && i % interval === 0) {
        result += "\n";
      }
      result += string[i];
    }
    return result;
  };

  const generateTemplate = async (data) => {
    const workbook = new excelJs.Workbook();
    const ws = workbook.addWorksheet("Simple Worksheet");
    const headers = [
        "Booking Id",
        "Hospital Name",
        "Dispatch Date",
        "Operation Date",
        "Slot",
        "Delivery Time",
        "Kit",
        "Sets",
        "Status",
        "Notes"
    ];

    const headerRow = ws.addRow(headers);

    headerRow.eachCell((cell) => {
        cell.font = {
            bold: true,
            size: 11,
        };
        cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFFF" } // Background color (e.g., yellow)
        };
        cell.border = {
            top: {style:"thin"},
            left: {style:"thin"},
            bottom: {style:"thin"},
            right: {style:"thin"}
        };
    });

    data.forEach((item) => {
        const kitset = item.bookingKitSetId.map(set => set.setId[0].set_name).join("\n");
        const kitName = item.bookingKitSetId.map(kit => kit.kitData[0].name).join("\n");
        let kitNameArray = kitName.split("\n");
        let uniqKitArray = [...new Set(kitNameArray)];
        uniqKitArray = uniqKitArray.join("\n");
        let notesLines = item?.additional_notes?.length > 40 ? addNewlines(item?.additional_notes, 40) : item?.additional_notes;
        
        const rowData = [
            item.booking_id,
            item.hospitalData[0].name,
            moment(item.delivery_date).format("ddd, MMMM Do, YYYY"),
            moment(item.operation_date).format("ddd, MMMM Do, YYYY"),
            item.operation_date_slot,
            item.delivery_date_slot,
            uniqKitArray,
            kitset,
            item.status,
            notesLines
            //item.additional_notes ? item.additional_notes : ""
            // item.bookingcollections[0] ? item.bookingcollections[0].comment : "",
        ];

        let setLength = item?.bookingKitSetId?.length ? item.bookingKitSetId.length : 1;

        let row = ws.addRow(rowData);

        row.height = setLength > 0 ? setLength*12 : 16;
        ws.getCell("G"+row?._number).alignment = { vertical: "bottom", horizontal: "left" };
        ws.getCell("H"+row?._number).alignment = { vertical: "bottom", horizontal: "left" };
        ws.getCell("J"+row?._number).alignment = { vertical: "bottom", horizontal: "left" };
        // ws.properties.outlineLevelRow = 1;
    });

    // ws.columns.forEach((col) => (col.width = 18));

    // Iterate through each column to calculate and set width
    ws.columns.forEach((column, columnIndex) => {
        let maxLength = 0;
        // Start from the third row (index 2) to skip the header row
        for (let rowIndex = 3; rowIndex <= ws.rowCount; rowIndex++) {
            const cellValue = ws.getCell(rowIndex, columnIndex + 1).value;
            if(columnIndex+1 === 7 || columnIndex+1 === 8 || columnIndex+1 === 10){
                const array = cellValue.split("\n");
                if(array?.length){
                    array.forEach(str => {
                        if (str.length > maxLength) {
                            maxLength = str.length;
                        }
                    });
                }else{
                    if (cellValue && cellValue.toString().length > maxLength) {
                        maxLength = cellValue.toString().length;
                    }
                }
            }else{
                if (cellValue && cellValue.toString().length > maxLength) {
                    maxLength = cellValue.toString().length;
                }
            }
        }
        // Set the width of the column based on the maximum length of its content
        column.width = maxLength < 15 ? 15 : maxLength + 2; // Add padding
    });

    ws.eachRow((row, rowNumber) => {
        if (rowNumber > 1) {
            row.eachCell((cell) => {
                cell.border = {
                    top: { style: "thin", color: { argb: "000000" } },
                    left: { style: "thin", color: { argb: "000000" } },
                    bottom: { style: "thin", color: { argb: "000000" } },
                    right: { style: "thin", color: { argb: "000000" } },
                };
            });
        }
        if (rowNumber === 1) {
            row.eachCell((cell) => {
                cell.font = {
                    bold: true,
                    size: 13,
                };
            });
        }
    });

    setIsLoading(false);
    const excelBlob = await workbook.xlsx.writeBuffer();    
    const excelUrl = URL.createObjectURL(
        new Blob([excelBlob], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
    );

    const link = document.createElement("a");
    link.href = excelUrl;
    link.download = "simple-booking";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(excelUrl);
    document.body.removeChild(link);
};

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  if (!user || user.data.user_role !== "admin") {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Navbar activePage="bookings" />
      <div className="container-fluid">
        <div className="row pt-4">
          <div className="col-md-6 col-lg-5">
            <ul className="sub-menu">
              <li>
                <Link to="/admin/bookings">Open</Link>
              </li>
              <li>
                <Link to="/admin/bookings/close">Closed</Link>
              </li>
              <li>
                <Link to="/admin/bookings/cancel">Cancelled</Link>
              </li>

              <li>
                <Link to="/admin/bookings/cancel" className="active">
                  Simple
                </Link>
              </li>

            </ul>
          </div>
          <div className="col-md-6 col-lg-2 mb-5 mb-md-4 mb-lg-0">
            <div className="form-search">
              <Input
                className="form-control mr-sm-2"
                type="text"
                placeholder="Search by booking ID"
                id="searchInput"
                //value={search}
                onKeyUp={(e) => {
                  searchKeyUp(e);
                }}
                onChange={debouncedSearch}
              />
            </div>
          </div>
          <div className="col-10 col-md-6 col-lg-3">
            <div className="label_adjust mx-lg-auto">
              <label className="small mb-0">Operation Date </label>
              <div className="row no-gutters">
                <div className="col">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    showClearButton={false}
                    placeholderText="From"
                    selected={searchOperationFromDate}
                    onChange={(e) => handleSearchOperationFromDate(e)}
                    className="form-control"
                    disabled={!loading ? false : true}
                    customInput={
                      <MaskedInput
                        pipe={autoCorrectedDatePipe}
                        mask={[
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        keepCharPositions={true}
                        guide={true}
                        showMask={false}
                      />
                    }
                  />
                </div>
                <div className="col">
                  <div className="mx-1">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      showClearButton={false}
                      placeholderText="To"
                      selected={searchOperationToDate}
                      onChange={(e) => handleSearchOperationToDate(e)}
                      className="form-control"
                      disabled={!loading ? false : true}
                      customInput={
                        <MaskedInput
                          //onMouseLeave={(e)=>handleClearToDate(e)}
                          pipe={autoCorrectedDatePipe}
                          mask={[
                            /\d/,
                            /\d/,
                            "/",
                            /\d/,
                            /\d/,
                            "/",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          keepCharPositions={true}
                          guide={true}
                          showMask={false}
                        />
                      }
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="label_adjust">
                    <label className="small mb-0">Delivery Date </label>
                    <span
                      className={
                        loading
                          ? "clear-disabled small ml-2 btn-clear"
                          : "small ml-2 btn-clear"
                      }
                    >
                      <a
                        style={{
                          position: "relative",
                          top: "5px",
                        }}
                        href="javascript:;"
                        onClick={() =>
                          loading ? "" : handleSearchDeliveryDate()
                        }
                      >
                        Clear
                      </a>
                    </span>
                    <div className="no-gutters">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        showClearButton={false}
                        selected={searchDeliveryFromDate}
                        onChange={(e) => handleSearchDeliveryFromDate(e)}
                        className="form-control"
                        disabled={!loading ? false : true}
                        customInput={
                          <MaskedInput
                            //onMouseLeave={(e)=>handleClearOperationDate(e)}
                            pipe={autoCorrectedDatePipe}
                            mask={[
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            keepCharPositions={true}
                            guide={true}
                            showMask={false}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-right col-2 col-md-6 col-lg-2">
            <div className="custom_fltr">
              <a onClick={() => handleFilterToggle()}>
                <FilterIcon className="img-svg"></FilterIcon>
              </a>
              <FilterTooltip
                handleFilterToggle={() => handleFilterToggle()}
                filterModal={filterModal}
                filterPoChase={filterPoChaseRef.current}
                filterCreateDate={filterCreateDateRef.current}
                handleFilterRefreesh={(data) => handleFilterRefreesh(data)}
                hospitals={hospitals}
                selectedHospitalProp={selectedHospital}
                kits={kits}
                selectedKitProp={selectedKit}
                surgeons={surgeons}
                selectedSurgeonProp={selectedSurgeon}
                operationsList={operationsList}
                selectedOperationProp={selectedOperation}
              />
            </div>

            <button
              className={
                !loading && !isLoading
                  ? "btn btn-default add_print ml-3"
                  : "btn btn-default add_print ml-3 dropdown-disabled"
              }
              onClick={() =>
                !loading && !isLoading && oldBookingList?.length
                  ? getXlsxData()
                  : ""
              }
              id="ReportDownload"
            >
              <i className="fa fa-print"></i>
            </button>

            {!loading && !isLoading ? (
              ""
            ) : (
              <UncontrolledTooltip target={"ReportDownload"}>
                {loading
                  ? "Please wait"
                  : isLoading
                  ? "Please wait"
                  : "No Record Found"}
              </UncontrolledTooltip>
            )}

            {dataXlsx && (
              <ExportSheet
                header={head}
                fileName={"bookings"}
                dataSource={dataXlsx}
                xlsx={XLSX}
              >
                <span></span>
              </ExportSheet>
            )}
          </div>
        </div>
        <InfinitScroll
          dataLength={newBookingList?.length}
          next={() => {
            newBookingList?.length > 12 ? getList(0) : () => {};
          }}
          scrollThreshold={"80%"}
          hasMore={true}
        >
         <div
					className="small mr-3 badge badge-info"
					style={{ fontWeight: 500, background: "#5d75e9",marginBottom:0 }}
				>
					{totalItemsCount} Records found
				</div>
					<div id="table-scroll" className="table-scroll mt-2">
            <table id="main-table" className="main-table">
              <thead>
                <tr>
                  <th scope="col" width="150">
                    Booking ID
                  </th>
                  <th scope="col" width="150">
                    Hospital Name{" "}
                  </th>
                  <th scope="col" width="140">
                    Surgeon Name
                  </th>
                  <th scope="col" width="220">
                    Operation Name{" "}
                  </th>
                  <th scope="col" width="190">
                    Kit
                  </th>
                  <th scope="col" width="190">
                    Set
                  </th>
                  <th scope="col" width="130">
                    Operation Date{" "}
                  </th>
                  <th scope="col" className="text-center" width="130">
                    Status{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                {newBookingList &&
                  newBookingList?.map((booking) => (
                    <tr key={booking?._id}>
                      <td>{booking?.booking_id}</td>
                      <td>{booking?.hospitalData[0]?.name}</td>
                      <td>
                        {booking?.bookingSurgeonData
                          ? `${booking?.bookingSurgeonData?.SurgeonDetail[0]?.first_name} ${booking?.bookingSurgeonData?.SurgeonDetail[0]?.last_name}`
                          : ""}
                      </td>
                      <td>{booking?.operation_name}</td>
                      <td>
                      {booking.bookingKitId &&
                        booking.bookingKitId.map(
                            (
                                kit,
                                ind,
                            ) => (
                                <React.Fragment
                                    key={
                                        ind
                                    }>
                                    <div>
                                        <span>
                                            {
                                                kit.kitId[0].name
                                            }{" "}
                                        </span>
                                    </div>
                                </React.Fragment>
                            ),
                        )}

                      </td>
                      <td>
                        {booking?.bookingKitSetId?.map(
                          (bookingKitSet, index) => (
                            <span key={index}>
                              {bookingKitSet?.setId[0]?.set_name}
                              {index !== booking?.bookingKitSetId?.length - 1
                                ? ", "
                                : ""}
                            </span>
                          )
                        )}
                      </td>
                      <td>
                        {dateFormat(booking?.operation_date, "ddd,mmm dS")}
                      </td>
                      <td className="text-center">
                        <span
                          className={
                            "mr-2 capsule " +
                            (booking.status == "booked" || booking.status == "close"
                              ? "green-dim "
                              : "red-dim ")
                          }
                        >
                          {booking.status == "dispatch" && (
                            <div>Dispatched</div>
                          )}
                          {booking.status != "dispatch" &&
                            capitalize(booking.status)}
                        </span>

                        {/* {booking?.status} */}
                      </td>
                    </tr>
                  ))}

                {newBookingList &&
                  Object.keys(newBookingList).length == 0 &&
                  loading == false && (
                    <tr className="text-center">
                      <td colSpan="8">No Record Found</td>
                    </tr>
                  )}

                {loading == true ||
                (loading == true &&
                  newBookingList &&
                  newBookingList.length == 0)
                  ? <Norecord />
                  : ""}
              </tbody>
            </table>
          </div>
        </InfinitScroll>
      </div>
      <Footer />
    </div>
  );
};

export default Index;
