import React, { useEffect, useState } from "react";
import dateFormat from "dateformat";
import { Modal, Button, ModalHeader, ModalFooter } from "react-bootstrap";

const SameDateModal = ({
	saveChangeHandle,
	checkDateModalClose,
	sameDatesInfo,
	show,
	onClose,
	onSubmit,
}) => {
	const [sameDatesData, setSameDatesData] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);

	const toggleModal = () => {
		setModalOpen(!modalOpen);
		checkDateModalClose();
		onClose();
	};

	const getDates = () => {
		setSameDatesData(sameDatesInfo);
	};

	useEffect(() => {
		if (show === true) {
			getDates();
		}
	}, [show]);

	return (
		<Modal centered backdrop="static" show={show} onHide={onClose}>
			<div className="row">
				<div style={{justifyContent:"center", display:"flex"}} className={"col-md-12 text-center"}>
					<ModalHeader className="text-danger text-center">
					Delivery date is the same as the operation date
					</ModalHeader>
				</div>
			</div>
			<Modal.Body>
				<div className="form-group">
					<label>Operation date</label>
					<div>
						<strong className="text-danger">
							{dateFormat(
								sameDatesData?.operation_date,
								"ddd, mmm dS"
							)}{" "}
							{sameDatesData?.operation_date_slot}
						</strong>
					</div>
				</div>

				{sameDatesData?.booking_type !== "loan" && (
					<div className="form-group">
						<label>Delivery date</label>
						<div>
							<strong className="text-danger">
								{dateFormat(
									sameDatesData?.delivery_date,
									"ddd, mmm dS"
								)}{" "}
							</strong>
						</div>
					</div>
				)}
				{sameDatesData?.booking_type === "loan" && (
					<div className="form-group">
						<label>Delivery date</label>
						<div>
							<strong className="text-danger">
								{dateFormat(
									sameDatesData?.delivery_date,
									"ddd, mmm dS"
								)}{" "}
								{sameDatesData?.delivery_date_slot}
							</strong>
						</div>
					</div>
				)}
			</Modal.Body>
			<ModalFooter>
				<div className="">
					<Button
						className="btn btn-primary"
						onClick={() => {
							saveChangeHandle(true);
							onSubmit(true);
						}}
					>
						Proceed
					</Button>
				</div>
				<div className="">
					<button
						style={{ color: "#000" }}
						className="btn btn-secondary"
						onClick={toggleModal}
					>
						Close
					</button>
				</div>
			</ModalFooter>
		</Modal>
	);
};

export default SameDateModal;

