import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {
	bookingService,
	returnBookingService,
	collectionBookingService,
} from "../../../../services";
import { Nav, Accordion } from "react-bootstrap";

let newDate = new Date();
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let separator = "-";
const todayDate = `${year}${separator}${
	month < 10 ? `0${month}` : `${month}`
}${separator}${date < 10 ? `0${date}` : `${date}`}`;

let yesterday = moment(todayDate).subtract(1, "days").format("YYYY-MM-DD");
let lastYear = moment(todayDate).subtract(360, "days").format("YYYY-MM-DD");

const NewDashboardSidebar = (props) => {
	const [activeAccordion, setActiveAccordion] = useState(
		localStorage.getItem("activeAccordion") || ""
	);
	const [consignmentDispatchCounts, setConsignmentDispatchCounts] = useState(
		props.dispatchCount
	);
	const [consignmentReturnCounts, setConsignmentReturnCounts] = useState(
		props.returnCount
	);
	const [
		unprocessedDispatchConsignmentCounts,
		setUnprocessedDispatchConsignmentCounts,
	] = useState(props.unprocessedDispatchCounts);

	const [
		unprocessedReturnConsignmentCounts,
		setUnprocessedReturnConsignmentCounts,
	] = useState(props.unprocessedReturnCounts);
	const [collectionConsignmentCounts, setCollectionConsignmentCounts] =
		useState(props.collectionCount);
	const [
		unprocessedConsignmentCollectionCounts,
		setUnprocessedConsignmentCollectionCounts,
	] = useState(props.unprocessedCollectionCounts);

	const [requestCounts, setRequestCounts] = useState(props.requestCount);
	const [dispatchCounts, setDispatchCounts] = useState(props.dispatchCount);
	const [returnCounts, setReturnCounts] = useState(props.returnCount);
	const [collectionCounts, setCollectionCounts] = useState(
		props.collectionCount
	);
	const [unprocessedCollectionCounts, setUnprocessedCollectionCounts] =
		useState(props.unprocessedCollectionCounts);
	const [unprocessedReturnCounts, setUnprocessedReturnCounts] = useState(
		props.unprocessedReturnCounts
	);
	const [unprocessedDispatchCounts, setUnprocessedDispatchCounts] = useState(
		props.unprocessedDispatchCounts
	);

	const [today, setToday] = useState(todayDate);

	const pendingCount = () => {
		bookingService
			.countByStatus({
				type: ["loan"],
				status: ["pending"],
				deliveryDateFiter: "",
				toDeliveryDateFiter: "",
			})
			.then((response) => {
				setRequestCounts(response.data);
			});
	};

	const dispatchCount = () => {
		bookingService
			.countBookingDispatch({
				bookedByFilter: "",
				hospitalFilter: "",
				type: ["loan"],
				status: ["booked", "dispatch"],
				deliveryDateFiter: today,
				toDeliveryDateFiter: today,
				search: "",
				limit: 50,
			})
			.then((response) => {
				setDispatchCounts(response.data);
			});
	};

	const uDispatchCount = () => {
		bookingService
			.uCountDispatch({
				bookedByFilter: "",
				hospitalFilter: "",
				type: ["loan"],
				status: ["dispatch", "booked"],
				deliveryDateFiter: lastYear,
				toDeliveryDateFiter: yesterday,
				search: "",
				limit: 50,
				isDispatched: true,
			})
			.then((response) => {
				setUnprocessedDispatchCounts(response.data);
			});
	};

	const returnCount = () => {
		returnBookingService
			.countByDate({
				type: ["loan"],
				status: ["dispatch", "close"],
				returnDateFiter: today,
				toReturnDateFiter: today,
			})
			.then((response) => {
				setReturnCounts(response.data);
			});
	};

	const uReturnCount = () => {
		returnBookingService
			.uCountByDate({
				bookedByFilter: "",
				hospitalFilter: "",
				type: ["loan"],
				status: ["dispatch", "close"],
				returnDateFiter: lastYear,
				toReturnDateFiter: yesterday,
				search: "",
				limit: 50,
				isReturned: true,
			})
			.then((response) => {
				setUnprocessedReturnCounts(response.data);
			});
	};

	const collectionCount = () => {
		collectionBookingService
			.countByDate({
				type: ["loan"],
				status: ["dispatch"],
				collectionDateFilter: today,
				toCollectionDateFilter: today,
			})
			.then((response) => {
				setCollectionCounts(response.data);
			});
	};

	const uCollectionCount = () => {
		collectionBookingService
			.uCountByDate({
				bookedByFilter: "",
				hospitalFilter: "",
				type: ["loan"],
				status: ["dispatch"],
				collectionDateFilter: lastYear,
				toCollectionDateFilter: yesterday,
				search: "",
				limit: 50,
			})
			.then((response) => {
				setUnprocessedCollectionCounts(response.data);
			});

		setUnprocessedCollectionCounts(0);
	};

	const pendingConsignmentCount = () => {
		bookingService
			.countByStatus({
				type: ["consignment"],
				status: ["pending"],
				deliveryDateFiter: "",
				toDeliveryDateFiter: "",
			})
			.then((response) => {
				console.log(response.data);
			});
	};

	const dispatchConsignmentCount = () => {
		bookingService
			.countBookingDispatch({
				bookedByFilter: "",
				hospitalFilter: "",
				type: ["consignment"],
				status: ["booked", "dispatch"],
				deliveryDateFiter: today,
				toDeliveryDateFiter: today,
				search: "",
				limit: 50,
			})
			.then((response) => {
				setConsignmentDispatchCounts(response.data);
			});
	};

	const uDispatchConsignmentCount = () => {
		bookingService
			.uCountDispatch({
				bookedByFilter: "",
				hospitalFilter: "",
				type: ["consignment"],
				status: ["dispatch", "booked"],
				deliveryDateFiter: lastYear,
				toDeliveryDateFiter: yesterday,
				search: "",
				limit: 50,
				isDispatched: true,
			})
			.then((response) => {
				setUnprocessedDispatchConsignmentCounts(response.data);
			});
	};

	const returnConsignmentCount = () => {
		returnBookingService
			.countByDate({
				type: ["consignment"],
				status: ["dispatch", "close"],
				returnDateFiter: today,
				toReturnDateFiter: today,
			})
			.then((response) => {
				setConsignmentReturnCounts(response.data);
			});
	};

	const uReturnConsignmentCount = () => {
		returnBookingService
			.uCountByDate({
				bookedByFilter: "",
				hospitalFilter: "",
				type: ["consignment"],
				status: ["dispatch", "close"],
				returnDateFiter: lastYear,
				toReturnDateFiter: yesterday,
				search: "",
				limit: 50,
				isReturned: true,
			})
			.then((response) => {
				setUnprocessedReturnConsignmentCounts(response.data);
			});
	};

	const collectionConsignmentCount = () => {
		collectionBookingService
			.countByDate({
				type: ["consignment"],
				status: ["dispatch"],
				collectionDateFilter: today,
				toCollectionDateFilter: today,
			})
			.then((response) => {
				setCollectionConsignmentCounts(response.data);
			});
	};

	const uCollectionConsignmentCount = () => {
		collectionBookingService
			.uCountByDate({
				bookedByFilter: "",
				hospitalFilter: "",
				type: ["consignment"],
				status: ["dispatch"],
				collectionDateFilter: lastYear,
				toCollectionDateFilter: yesterday,
				search: "",
				limit: 50,
			})
			.then((response) => {
				setUnprocessedConsignmentCollectionCounts(response.data);
			});

		setUnprocessedConsignmentCollectionCounts(0);
	};
	const getCount = () => {
		pendingCount();
		dispatchCount();
		returnCount();
		collectionCount();
		uDispatchCount();
		uReturnCount();
		uCollectionCount();

		pendingConsignmentCount();
		dispatchConsignmentCount();
		uDispatchConsignmentCount();
		returnConsignmentCount();
		uReturnConsignmentCount();
		collectionConsignmentCount();
		uCollectionConsignmentCount();
	};

	const totalUnprocessedCounts =
		(unprocessedCollectionCounts != "" && unprocessedCollectionCounts > 0
			? unprocessedCollectionCounts
			: unprocessedConsignmentCollectionCounts != "" &&
			  unprocessedConsignmentCollectionCounts > 0
			? unprocessedConsignmentCollectionCounts
			: 0) +
		(unprocessedReturnCounts != "" && unprocessedReturnCounts > 0
			? unprocessedReturnCounts
			: unprocessedReturnConsignmentCounts != "" &&
			  unprocessedReturnConsignmentCounts > 0
			? unprocessedReturnConsignmentCounts
			: 0) +
		(unprocessedDispatchCounts != "" && unprocessedDispatchCounts > 0
			? unprocessedDispatchCounts
			: unprocessedDispatchConsignmentCounts != "" &&
			  unprocessedDispatchConsignmentCounts > 0
			? unprocessedDispatchConsignmentCounts
			: 0);

	useEffect(() => {
		setToday(todayDate);
		getCount();
	}, []);

	useEffect(() => {
		if (props?.rejectStatus) {
			pendingCount();
			pendingConsignmentCount();
		}
	}, [props?.rejectStatus]);

	useEffect(() => {
		if (props?.unprocessedDispatchCounts) {
			uDispatchCount();
			uDispatchConsignmentCount();
		}
	}, [props?.unprocessedDispatchCounts]);

	useEffect(() => {
		if (props?.unprocessedReturnCounts) {
			uReturnCount();
			uReturnConsignmentCount();
		}
	}, [props?.unprocessedReturnCounts]);

	useEffect(() => {
		if (props?.unprocessedCollectionCounts) {
			uCollectionCount();
			uCollectionConsignmentCount();
		}
	}, [props?.unprocessedCollectionCounts]);

	const handleAccordionChange = (key) => {
		const newActiveKey = activeAccordion === key ? "" : key;
		setActiveAccordion(newActiveKey);
		localStorage.setItem("activeAccordion", newActiveKey);
	};

	return (
		<div>
			<Nav className="list-group mt-4 pr-4" activeKey="/home">
				<Accordion activeKey="0">
					<Accordion.Item eventKey="0">
						<Accordion.Body>
							<Nav.Item
								className={
									props.activePage === "request"
										? "active"
										: ""
								}
							>
								<Nav.Link
									as={Link}
									to="/admin/dashboard/booking-request"
									eventKey="link-2"
									onClick={() => handleAccordionChange("-1")}
								>
									Booking Requests
									<span className="badge badge-primary badge-pill p-right">
										{requestCounts !== "" &&
											requestCounts > 0 &&
											requestCounts}
									</span>
								</Nav.Link>
							</Nav.Item>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
				<Accordion activeKey={activeAccordion}>
					<Accordion.Item eventKey="1">
						<Accordion.Header
							onClick={() => handleAccordionChange("1")}
							className={
								[
									"dispatch",
									"u-dispatch",
									"consignment-dispatch",
									"u-consignment-dispatch",
								].includes(props.activePage) == true
									? "active "
									: ""
							}
						>
							Dispatch
							<span className="badge badge-primary badge-pill p-right">
								{(dispatchCounts !== "" && dispatchCounts > 0
									? dispatchCounts
									: 0) +
									(consignmentDispatchCounts !== "" &&
									consignmentDispatchCounts > 0
										? consignmentDispatchCounts
										: 0)}
							</span>
						</Accordion.Header>
						<Accordion.Body>
							<Nav.Item
								className={
									props.activePage == "dispatch"
										? "active "
										: ""
								}
							>
								<Nav.Link
									as={Link}
									to="/admin/dashboard/booking-dispatch"
									eventKey="link-2"
								>
									Loan
									<span className="badge badge-primary badge-pill p-right">
										{dispatchCounts != "" &&
											dispatchCounts > 0 &&
											dispatchCounts}
									</span>
								</Nav.Link>
							</Nav.Item>
							<Nav.Item
								className={
									props.activePage == "consignment-dispatch"
										? "active "
										: ""
								}
							>
								<Nav.Link
									as={Link}
									to="/admin/dashboard/consignment-dispatch"
									eventKey="link-2"
								>
									Consignment
									<span className="badge badge-primary badge-pill p-right">
										{consignmentDispatchCounts != "" &&
											consignmentDispatchCounts > 0 &&
											consignmentDispatchCounts}
									</span>
								</Nav.Link>
							</Nav.Item>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="2">
						<Accordion.Header
							onClick={() => handleAccordionChange("2")}
							className={
								[
									"return",
									"u-return",
									"consignment-return",
									"u-consignment-return",
								].includes(props.activePage) == true
									? "active "
									: ""
							}
						>
							Returns
							<span className="badge badge-primary badge-pill p-right">
								{(returnCounts !== "" && returnCounts > 0
									? returnCounts
									: 0) +
									(consignmentReturnCounts !== "" &&
									consignmentReturnCounts > 0
										? consignmentReturnCounts
										: 0)}
							</span>
						</Accordion.Header>
						<Accordion.Body>
							<Nav.Item
								className={
									props.activePage == "return"
										? "active "
										: ""
								}
							>
								<Nav.Link
									as={Link}
									to="/admin/dashboard/booking-returns"
									eventKey="link-2"
								>
									Loan
									<span className="badge badge-primary badge-pill p-right">
										{returnCounts != "" &&
											returnCounts > 0 &&
											returnCounts}
									</span>
								</Nav.Link>
							</Nav.Item>
							<Nav.Item
								className={
									props.activePage == "consignment-return"
										? "active "
										: ""
								}
							>
								<Nav.Link
									as={Link}
									to="/admin/dashboard/consignment-returns"
									eventKey="link-2"
								>
									Consignment
									<span className="badge badge-primary badge-pill p-right">
										{consignmentReturnCounts != "" &&
											consignmentReturnCounts > 0 &&
											consignmentReturnCounts}
									</span>
								</Nav.Link>
							</Nav.Item>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="3">
						<Accordion.Header
							onClick={() => handleAccordionChange("3")}
							className={
								[
									"collection",
									"u-collection",
									"consignment-collection",
									"u-consignment-collection",
								].includes(props.activePage) == true
									? "active "
									: ""
							}
						>
							Collections
							<span className="badge badge-primary badge-pill p-right">
								{(collectionCounts !== "" &&
								collectionCounts > 0
									? collectionCounts
									: 0) +
									(collectionConsignmentCounts !== "" &&
									collectionConsignmentCounts > 0
										? collectionConsignmentCounts
										: 0)}
							</span>
						</Accordion.Header>
						<Accordion.Body>
							<Nav.Item
								className={
									props.activePage == "collection"
										? "active "
										: ""
								}
							>
								<Nav.Link
									as={Link}
									to="/admin/dashboard/booking-collections"
									eventKey="link-2"
								>
									Loan
									<span className="badge badge-primary badge-pill p-right">
										{collectionCounts != "" &&
											collectionCounts > 0 &&
											collectionCounts}
									</span>
								</Nav.Link>
							</Nav.Item>
							<Nav.Item
								className={
									props.activePage == "consignment-collection"
										? "active "
										: ""
								}
							>
								<Nav.Link
									as={Link}
									to="/admin/dashboard/consignment-collections"
									eventKey="link-2"
								>
									Consignment
									<span className="badge badge-primary badge-pill p-right">
										{collectionConsignmentCounts != "" &&
											collectionConsignmentCounts > 0 &&
											collectionConsignmentCounts}
									</span>
								</Nav.Link>
							</Nav.Item>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="4">
						<Accordion.Header
							onClick={() => handleAccordionChange("4")}
							className={
								["booking-transfer", "equipment"].includes(
									props.activePage
								) == true
									? "active "
									: ""
							}
						>
							Others
						</Accordion.Header>
						<Accordion.Body>
							<Nav.Item
								className={
									"list-group-item d-flex justify-content-between align-items-center " +
									(props.activePage == "equipment"
										? "active "
										: "")
								}
							>
								<Nav.Link
									as={Link}
									to="/admin/dashboard/equipment-used-report"
									eventKey="link-2"
								>
									Equipment Used Report
								</Nav.Link>
							</Nav.Item>
							<Nav.Item
								className={
									"list-group-item d-flex justify-content-between align-items-center " +
									(props.activePage == "booking-transfer"
										? "active "
										: "")
								}
							>
								<Nav.Link
									as={Link}
									to="/admin/dashboard/booking-transfer"
									eventKey="link-2"
								>
									{" "}
									Booking Transfer
								</Nav.Link>
							</Nav.Item>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="5">
						<Accordion.Header
							onClick={() => handleAccordionChange("5")}
							className={
								[
									"u-collection",
									"u-dispatch",
									"u-return",
								].includes(props.activePage) == true
									? "active "
									: ""
							}
						>
							Unprocessed
							<span className="badge badge-primary badge-pill p-right">
								{totalUnprocessedCounts > 0
									? totalUnprocessedCounts
									: ""}
							</span>
						</Accordion.Header>
						<Accordion.Body>
							<Nav.Item
								className={
									props.activePage == "u-dispatch"
										? "active "
										: ""
								}
							>
								<Nav.Link
									as={Link}
									to="/admin/dashboard/booking-dispatch/unprocessed"
									eventKey="link-2"
								>
									Dispatch
									<span className="badge badge-primary badge-pill p-right">
										{(unprocessedDispatchCounts != "" &&
											unprocessedDispatchCounts > 0 &&
											unprocessedDispatchCounts) ||
											(unprocessedDispatchConsignmentCounts !=
												"" &&
												unprocessedDispatchConsignmentCounts >
													0 &&
												unprocessedDispatchConsignmentCounts)}
									</span>
								</Nav.Link>
							</Nav.Item>

							<Nav.Item
								className={
									props.activePage == "u-return"
										? "active "
										: ""
								}
							>
								<Nav.Link
									as={Link}
									to="/admin/dashboard/booking-returns/unprocessed"
									eventKey="link-2"
								>
									Returns
									<span className="badge badge-primary badge-pill p-right">
										{(unprocessedReturnCounts != "" &&
											unprocessedReturnCounts > 0 &&
											unprocessedReturnCounts) ||
											(unprocessedReturnConsignmentCounts !=
												"" &&
												unprocessedReturnConsignmentCounts >
													0 &&
												unprocessedReturnConsignmentCounts)}
									</span>
								</Nav.Link>
							</Nav.Item>

							<Nav.Item
								className={
									props.activePage == "u-collection"
										? "active "
										: ""
								}
							>
								<Nav.Link
									as={Link}
									to="/admin/dashboard/booking-collections/unprocessed"
									eventKey="link-2"
								>
									Collections
									<span className="badge badge-primary badge-pill p-right">
										{(unprocessedCollectionCounts != "" &&
											unprocessedCollectionCounts > 0 &&
											unprocessedCollectionCounts) ||
											(unprocessedConsignmentCollectionCounts !=
												"" &&
												unprocessedConsignmentCollectionCounts >
													0 &&
												unprocessedConsignmentCollectionCounts)}
									</span>
								</Nav.Link>
							</Nav.Item>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</Nav>
		</div>
	);
};
export default NewDashboardSidebar;
